import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'
import indexStyles from './index.module.scss'

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home"/>
      <h1>Welcome to my website where I try to prove to you that I can engineer software.</h1>
      <p><Link className={indexStyles.contactButton} to="/contact"> Need a developer? Hire me.</Link></p>
    </Layout>
  )
}

export default IndexPage